.fullWidth{
   width:100%;
}
.halfWidth{
    width:50%;
}
.quarterWidth{
    width:25%;
}
.padding20{
    padding:20px
}
.padding10{
    padding:10px
}
.paddingBottom10{
    padding-bottom:10px
}

.spaceEvenly{
    display:flex;
    justify-content: space-evenly;
}
.smallSelect{
    height:40px;
}
.centerVertically{
    display:flex;
    align-items: center;
}
.flexWrap{
    display: flex;
    flex-wrap: wrap;
}
.marginTop30{
    margin-top:30px;
}

.backDropZIndex{
    z-index: 1 !important;
}

.redColorHelperText{
    color: #f44336 !important;
}
