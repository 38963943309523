.RegisterDetails .newAttributesGrid {
  margin-bottom: 10px;
}

.RegisterDetails .existingAttributesGrid {
  margin-bottom: 10px;
}

.RegisterDetails .fullWidth {
  width: 100%;
}

.RegisterDetails .padding20 {
  padding:20px;
}