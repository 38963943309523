.background {
  /* background-image: url("../../images/loginBkg.svg"); */
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  background-size: cover;
}

.centered {
  position: fixed;
  top: 45%;
  left: 50%;
  width: fit-content;
  height: fit-content;
  transform: translate(-50%, -50%);
}

.appName {
  color: white;
  font-size: 41px;
  font-family: "Roboto", sans-serif;
  text-align: right;
  display: block;
}

.segmentName {
  color: white;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-align: right;
  display: block;
}

.button {
  background-color: #02ba21;
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  width: 110px;
  height: 34px;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.button:hover {
  background-color: #02ba21;
}
