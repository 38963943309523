.roc-accordion {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  margin: 8px 0;
}

.roc-accordion .roc-accordion-summary {
  border-left: solid 10px;
  background-color: #F1F4F9;
  border-radius: 5px;
}

.roc-accordion .roc-accordion-summary--past {
  border-left-color: #DA3A3A;
}

.roc-accordion .roc-accordion-summary--urgent {
  border-left-color: #FEBB4C;
}

.roc-accordion .roc-accordion-summary--upcoming {
  border-left-color: #DFDADA;
}

.roc-accordion .section-label,
.quantity-accordion .section-label {
  color: #005ebd;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  width: 100%;
}

.roc-accordion .section-value,
.quantity-accordion .section-value {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 14px;
  width: 100%;
}

.roc-accordion .status-chip-container .roc-status {
  height: 9px;
  margin: 0 1px;
  width: 9px;
}

.roc-accordion .status-chip-container .roc-status:first-child {
  margin-left: 0;
}

.roc-accordion .status-chip-container .roc-status:last-child {
  margin-right: 0;
}

/*  Overrides  */
.roc-accordion.MuiAccordion-root::before {
  display: none;
}

.roc-accordion.MuiAccordion-root:first-child,
.roc-accordion.MuiAccordion-root:last-child {
  border-radius: 8px;
}

.roc-accordion.MuiAccordion-root.MuiAccordion-rounded {
  border-radius: 8px;
}
