.activities-accordion .activities-accordion__pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  width: fit-content;
}

.activities-accordion__pagination .pagination__button {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.activities-accordion__pagination .pagination__link {
  align-self: flex-start;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 20px;
  margin-top: 8px;
  padding: 3px;
}

.activities-accordion__pagination .pagination__link.pagination__link--selected {
  color: rgba(0, 0, 0, 0.26);
  cursor: default;
}
