.ROCTable .padding20 {
  padding: 20px;
}

.ROCTable .marginBottom20 {
  margin-bottom: 20px;
}

.ROCTable table.credits-table th.collapse-arrow {
  width: 10%;
}

.ROCTable table.credits-table th.roc-id {
  width: 10%;
}

.ROCTable table.credits-table th.roc-details {
  width: 30%;
}

.ROCTable table.credits-table th.save-roc-btn {
  width: 10%;
}

.ROCTable table.credits-table th.delete-roc-btn {
  width: 10%;
}

.ROCTable table.activity-steps-table th {
  width: 50%;
}