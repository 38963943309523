.QuantityActivitiesTable.padding20 {
  padding: 20px;
}

.QuantityActivitiesTable .filterColumn {
  max-width: 200px;
  overflow-x: hidden;
}

.QuantityActivitiesTable .stickyColumn {
  position: sticky;
  left: 0;
  z-index:  1;
}

.QuantityActivitiesTable .whiteBackground {
  background-color: #fff;
}

.QuantityActivitiesTable .description {
  min-width: 200px;
}

.QuantityActivitiesTable .editDatePickerWidth {
  min-width: 180px;
}

.QuantityActivitiesTable .editQuantityMinWidth {
  min-width: 140px;
}
