 .bottomBorder {
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
  }

.dialogWidth [role="dialog"]{
    width:50%;
  }

.noPadding{
  padding:0px !important;
}

.flexAlignLeft{
  display: flex;
  justify-content: flex-end;
}

.flexAlignRight{
  display: flex;
  justify-content: flex-start;
}