.ProjectUsers .backDropZIndex {
  z-index: 1 !important;
}

.ProjectUsers .padding20 {
  padding: 20px;
}

.ProjectUsers .fullWidth {
  width:100%;
}

.ProjectUsers .spaceEvenly {
  display:flex;
  justify-content: space-evenly;
}

.ProjectUsers .marginBottom20 {
  margin-bottom: 20px;
}

.helpButton:hover{
  cursor: pointer;
}

@media only screen and (min-width: 1280px) {
  .ProjectUsers .marginTop{
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1280px) {
  /* For mobile phones: */
  .ProjectUsers .marginTop{
    margin-top: 0px;
  }
}