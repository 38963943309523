.LevelsSelectionTile.spaceEvenly {
  display:flex;
  justify-content: space-evenly;
}

.LevelsSelectionTile.fullWidth {
  width:100%;
}

.LevelsSelectionTile.marginBottom20 {
  margin-bottom: 20px;
}

.LevelsSelectionTile .padding20 {
  padding: 20px;
}

.LevelsSelectionTile .tree-panel {
  overflow: auto;
}