.search-box {
  padding: 20px;
}

.search-box.search-title {
  padding-bottom: 0px;
}

.search-box .search-table-row:active {
  background-color: whitesmoke;
}

.search-box.search-table {
  padding: 0px;
  padding-top: 20px;
}
