.Report .centerHorizontally{
  display: flex;
  justify-content: center;
}

.Report .backDropZIndex {
  z-index: 1 !important;
}

.Report .fullWidth {
  width:100%;
}

.Report .spaceEvenly {
  display:flex;
  justify-content: space-evenly;
}

.Report .marginBottom20 {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1280px) {
  .Report .marginTop{
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1280px) {
  /* For mobile phones: */
  .Report .marginTop{
    margin-top: 0px;
  }
}

.Report iframe {
  border: none;
}

.Report .embedContainer {
  height: calc((85vw) * 0.5625);
	margin: 1% auto;
}

.Report .padding20 {
  padding: 20px;
}