tr.MuiTableRow-root.main-row {
  border-top: 1px solid #e0e0e0;
}

.main-row > .MuiTableCell-root {
  border-bottom: unset;
}

tr.collapsable-row > td.MuiTableCell-root {
  padding-top: 0;
}

div.category-section-title {
  margin-top: 12px;
  font-size: 1rem;
}

div.new-categories-grid {
  margin-bottom: 16px;
}

.inner-table {
  margin-bottom: 12px;
}

.grey-table {
  background-color: #f8f8f8;
}