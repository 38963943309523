.fullWidth{
    width:100%;
 }

 .l2Table .verticalPadding{
     padding-top:20px;
     padding-bottom:20px;
 }

 .l2Table .noRightPadding{
    padding-right:0px !important;
    
}

.l2Table .noLeftPadding{
    padding-left:0px !important;
    
}

 .l2Table .noMargin{
     margin:0px;
 }

 .l2Table .centerHorizontal{
    display: flex;
    justify-content: center;
 }
