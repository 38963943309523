.RuleOfCredits .backDropZIndex {
  z-index: 1 !important;
}

.RuleOfCredits .fullWidth {
  width:100%;
}

.RuleOfCredits .spaceEvenly {
  display:flex;
  justify-content: space-evenly;
}

.RuleOfCredits .marginBottom20 {
  margin-bottom: 20px;
}

/* .RuleOfCredits .padding10 {
  padding: 10px;
}

.RuleOfCredits .padding20 {
  padding: 20px;
}

.RuleOfCredits .marginTop30 {
  margin-top: 30px;
}

.RuleOfCredits table.new-activity-steps th {
  width: 50%;
} */

@media only screen and (min-width: 1280px) {
  .RuleOfCredits .marginTop{
    margin-top:20px;
  }
}

@media only screen and (max-width: 1280px) {
  /* For mobile phones: */
  .RuleOfCredits .marginTop{
    margin-top:0px;
  }
}
