.LevelSummary.padding20 {
  padding: 20px;
}

.LevelSummary .level-summary-table-container tr th.id {
  width: 5%;
}

.LevelSummary .level-summary-table-container tr th.name {
  width: 35%;
}

.LevelSummary .level-summary-table-container tr th.type {
  width: 10%;
}

.LevelSummary .level-summary-table-container tr th.plan,
.LevelSummary .level-summary-table-container tr th.forecast,
.LevelSummary .level-summary-table-container tr th.weighted-plan,
.LevelSummary .level-summary-table-container tr th.weighted-forecast,
.LevelSummary .level-summary-table-container tr th.weighted-actual {
  width: 8%;
}

.LevelSummary .level-summary-table-container tr th.actual {
  width: 10%;
}