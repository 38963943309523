.LevelsDetails .spaceEvenly{
  display:flex;
  justify-content: space-evenly;
}

.LevelsDetails .flexWrap{
  display: flex;
  flex-wrap: wrap;
}

.LevelsDetails .fullWidth{
  width:100%;
}

.LevelsDetails .greenBackground{
  background-color: #4caf50;
  color:#fff
}

.LevelsDetails .greenBackground:hover{
  background-color: #388e3c;
}

.LevelsDetails .padding0{
  padding:0px;
}

.LevelsDetails .alignTextCenter{
  align-self: center;
  text-align: center;
}

.LevelDetails .marginTop30 {
  margin-top: 30px;
}

.LevelDetails .marginBottom20 {
  margin-bottom: 20px;
}

.LevelDetails .padding10{
  padding:10px;
}

.LevelDetails .paddingBottom10{
  padding-bottom:10px
}

@media only screen and (min-width: 1280px) {
  .LevelDetails .marginTop{
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1280px) {
  /* For mobile phones: */
  .LevelDetails .marginTop{
    margin-top: 0px;
  }
}

.LevelDetails .backDropZIndex {
  z-index: 1 !important;
}

.LevelDetails .dialogWidth [role="dialog"]{
  width:50% !important;
}

 .MuiDialogContent-root #weightDialog{
  overflow-y: scroll !important;
  padding: 0px !important;
}

.dialogWidth [role="dialog"]{
  max-width:700px !important;
}

.dialogWidth900 [role="dialog"]{
  max-width:900px !important;
}

.MuiDialog-container{
  width:100%;
}

