.activities-table-tile.space-evenly {
  display:flex;
  justify-content: space-evenly;
}

.activities-table-tile.full-width {
  width:100%;
}

.activities-table-tile.padding-20 {
  padding: 20px;
}
