.UserManagement {
  margin-top: 30px;
}

.UserManagement .new-user-form {
  width: 100%;
  padding: 20px;
}

.UserManagement .new-user-form-title {
  margin-bottom: 20px;
}

.UserManagement #pp-new-user .people-picker{
  border-radius: 4px !important;
}

.UserManagement .tableRowColor{
  background-color:inherit;
}

.UserManagement .backDropZIndex {
  z-index: 1 !important;
}

.UserManagement .padding20 {
  padding: 20px;
}

.UserManagement .fullWidth {
  width:100%;
}