.fullWidth{
    width:100%;
 }
 .halfWidth{
     width:50%;
 }
 .quarterWidth{
     width:25%;
 }
 .padding20{
     padding:20px
 }
 .padding10{
     padding:10px
 }
 .paddingBottom10{
     padding-bottom:10px
 }
 
 .spaceEvenly{
     display:flex;
     justify-content: space-evenly;
 }
 .smallSelect{
     height:40px;
 }
 .centerVertically{
     display:flex;
     align-items: center;
 }
 .centerHorizontally{
     display:flex;
     justify-content: center;
 }
 .flexWrap{
     display: flex;
     flex-wrap: wrap;
 }
 .marginBottom20{
     margin-bottom:20px;
 }
 .marginTop20{
    margin-top:20px;
 }
 @media only screen and (min-width: 1280px) {
    .marginTop{
        margin-top:20px;
    }
   
  }

  @media only screen and (max-width: 1280px) {
    /* For mobile phones: */
    .marginTop{
        margin-top:0px;
    }
  }
 
.ProjectDetails .backDropZIndex {
  z-index: 1 !important;
}