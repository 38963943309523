*:focus {
    outline: none !important;
}

.whiteColor{
    color:#fafafa;
}


.whiteColor .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track{
    color:#000;
}

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}