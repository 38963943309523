.ROCActivitiesTable.padding20,
.ROCActivitiesTable .padding20 {
  padding: 20px;
}

.ROCActivitiesTable .activities-table-container table {
  width: 2000px;
}

.ROCActivitiesTable .stickyColumn{
  position: sticky;
  left: 0;
  z-index:  1;
}

.ROCActivitiesTable .whiteBackground{
  background-color: #fff;
}

.ROCActivitiesTable .attributeMinWidth{
  min-width:180px;
}