.NewROCForm .fullWidth,
.NewROCForm.fullWidth {
  width:100%;
}

.NewROCForm.spaceEvenly {
  display:flex;
  justify-content: space-evenly;
}

.NewROCForm .padding20 {
  padding: 20px;
}