.Activities .fullWidth {
  width:100%;
}

.Activities .spaceEvenly {
  display:flex;
  justify-content: space-evenly;
}

.Activities .marginBottom20 {
  margin-bottom: 20px;
}

.Activities .marginTop30 {
  margin-top: 30px;
}

@media only screen and (min-width: 1280px) {
  .Activities .marginTop{
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1280px) {
  /* For mobile phones: */
  .Activities .marginTop{
    margin-top: 0px;
  }
}

.Activities .backDropZIndex {
  z-index: 1 !important;
}