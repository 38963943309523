.dashBoardTable .successColor{
    color:#4caf50 !important;
}

.dashBoardTable .warningColor{
   color: #ff9800 !important;
}

.dashBoardTable .errorColor{
    color: #f44336 !important;
} 

.dashBoardTable .tableRow:hover{
    cursor: pointer;
} 

